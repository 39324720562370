<template>
  <v-dialog v-model="dialog" persistent max-width="30%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{
              labelBtn != "Salvar"
                ? "Editar Status Consórcio"
                : "Cadastrar Status Consórcio"
            }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                label="ID"
                v-model="form.id_status"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                label="Parcelas"
                v-model="form.qtde_parc"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                label="Descrição"
                v-model="form.nome_status"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                label="Status"
                v-model="form.status"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" lg="3">
              <v-autocomplete
                label="Ativo"
                v-model="form.ativo"
                :items="[
                  { text: 'Ativo', value: 'S' },
                  { text: 'Inativo', value: 'N' }
                ]"
                :rules="[rules.required]"
                :multiple="false"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="labelBtn != 'Salvar' ? editarStatus() : cadastrarStatus()"
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import comissao from "@/services/http/comissaoService";

export default {
  name: "ModalCadStatus",

  mixins: [rules],
  components: {},

  props: {
    dialog: {
      type: Boolean
    },

    labelBtn: {
      type: String
    },

    statusConsorcio: {
      type: Object
    }
  },

  data() {
    return {
      form: {},
      items: [
        {
          ativo: "Ativo",
          value: "S"
        },
        {
          ativo: "Inativo",
          value: "N"
        }
      ]
    };
  },

  watch: {
    statusConsorcio() {
      if (this.statusConsorcio) {
        this.form = this.statusConsorcio;
      }
    }
  },

  methods: {
    async cadastrarStatus() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await comissao()
          .statusConsorcio()
          .store(this.form, {
            notification: true,
            message: "Status Consórcio cadastrado com sucesso!"
          }),
          this.close();
      }
    },

    async editarStatus() {
      const validateStatus = this.$refs.form.validate();
      if (validateStatus) {
        await comissao()
          .statusConsorcio(this.form.id_status)
          .update(this.form, {
            notification: true,
            message: "Status Consórcio editado com sucesso!"
          }),
          this.$notify({
            type: "success",
            text: "Status Consórcio editado"
          });

        this.close();
      }
    },

    close() {
      this.$emit("close");
      this.$refs.form.reset();
    }
  }
};
</script>

<style></style>
